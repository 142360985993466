<template>
  <div class="pdl40 mrb40">
    <!-- <div>编辑的字段{{editField}}</div> -->
    <el-table :data="tableField" ref="tableField" border style="width: 100%;">
      <el-table-column prop="type" label="规格名" width="180">
        <template slot-scope="scope">
          <el-input v-model="scope.row.name" placeholder="" clearable @blur='titileFieldBlur(scope.row,scope)' @focus='editField = scope.row.name'></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="value" label="规格值">
        <template slot-scope="scope">
          <div class="flex flex-wrap ">
            <div class="mrr10 mrb5 mrt10 flex" v-for="(item,index) in scope.row.items" :key="index">
              <div class="mrr10">
                <el-input v-model="item.name" placeholder="" clearable style="width: 200px;" @blur='fieldBlur(scope.row,item.name,index,scope.$index)' @clear="fieldClear()">
                  <template slot="append"><i class="el-icon-close" @click="deletecustom(scope.$index,index)"></i></template></el-input>
              </div>
              <file-picmini v-if="scope.$index ==0" :file='item.cover' @input='item.cover = $event;fieldFile()'></file-picmini>
            </div>
            <div class="mrt10">
              <el-button type="primary" icon="el-icon-plus" @click="standard_value(scope)"></el-button>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="120">
        <template slot-scope="scope">
          <div style="display: flex">
            <el-button type="primary" @click="addField( scope.row,scope.$index)" v-if="scope.$index == 0">添加</el-button>
            <el-button type="danger" @click="delDataBase( scope.row,scope.$index)" v-else>移除</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <!-- 第二部分表格 -->
    <!-- {{ picActive }} -->
    <!-- {{formField}} -->
    <!-- 表格值{{formData}} -->
    <el-table :data="formData" border style="width: 100%" height="800px">
      <el-table-column :prop="item" :label="item" v-for="(item,i) in formField" :key="i">
      </el-table-column>
      <el-table-column prop="type" label="库存">
        <template slot-scope="scope">
          <el-input v-model="scope.row.stock" placeholder="" clearable style="width: 100px;"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="type" label="价格">
        <template slot-scope="scope">
          <el-input v-model="scope.row.price" placeholder="" clearable style="width: 100px;"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="cover" label="图片">
        <template slot-scope="scope">
          <file-picmini :file='scope.row.cover' @input='scope.row.cover = $event'></file-picmini>
        </template>
      </el-table-column>
      <el-table-column prop="type" label="规格编码">
        <template slot-scope="scope">
          <el-input v-model="scope.row.spec_sn" placeholder="" clearable style="width: 100px;"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="type" label="状态">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.is_show" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="0" @change="statusClick(scope.row)">
          </el-switch>
        </template>
      </el-table-column>
    </el-table>
    <div class="flex mrt20">
      <div class="flex mrr20">
        <div class="mrr20">批量修改库存</div>
        <el-input v-model="batchForm.allStock" placeholder="" clearable style="width: 200px;"></el-input>
      </div>
      <div class="flex mrr20">
        <div class="mrr20">批量修改价格</div>
        <el-input v-model="batchForm.allPrice" placeholder="" clearable style="width: 200px;"></el-input>
      </div>
      <div class="flex mrr20">
        <div class="mrr20">批量修改规格编码</div>
        <el-input v-model="batchForm.allSpec_sn" placeholder="" clearable style="width: 200px;"></el-input>
      </div>
      <div> <el-button type="primary" size="small" @click='batchClick(batchForm)'>批量修改</el-button></div>
    </div>
  </div>
</template>

<script>
import filePicmini from "@/components/common/filePicmini";
export default {
  components: { filePicmini },
  props: {},
  data() {
    return {
      batchForm: {
        allStock: "",
        allSpec_sn: "",
        allPrice: "",
      },
      picActive: false,
      sku: {},
      editField: "",
      tableField: [
        {
          items: [],
          name: "", //规格名
          cover: "",
          stock: "",
          price: "",
          spec_sn: "",
          is_show: 1,
        },
      ],
      formField: [],
      formData: [],
      detailData: [],
      editData: [],
    };
  },

  created() {},
  mounted() {},
  computed: {},

  methods: {
    statusClick(row) {
      console.log(row);
    },

    batchClick(row) {
      if (row.allStock !== "") {
        this.formData.forEach((item) => {
          item.stock = this.batchForm.allStock;
        });
      }
      if (row.allPrice !== "") {
        this.formData.forEach((item) => {
          item.price = this.batchForm.allPrice;
        });
      }
      if (row.allSpec_sn !== "") {
        this.formData.forEach((item) => {
          item.spec_sn = this.batchForm.allSpec_sn;
        });
      }
    },
    getFormData() {
      console.log("this.tableField================", this.tableField);

      var dataSkuItemSource = this.tableField;

      //综合规格详情
      var cartesianProductOf = function () {
        return Array.prototype.reduce.call(
          arguments,
          function (a, b) {
            var ret = [];
            a.forEach(function (a) {
              b.forEach(function (b) {
                ret.push(a.concat([b]));
              });
            });
            return ret;
          },
          [[]]
        );
      };

      var cartesianTableDataOf = function (arr, skuTypeArr) {
        console.log("arr", arr);
        console.log("skuTypeArr", skuTypeArr);

        var arrY = [];

        var arrCover = [];

        for (var j = 0; j < arr.length; j++) {
          var obj = arr[j];

          var objX = {
            cover: "",
            stock: "",
            price: "",
            spec_sn: "",
            is_show: 1,
          };

          for (var i = 0; i < skuTypeArr.length; i++) {
            let cover_arr_items = skuTypeArr[i].items;

            let value = obj[i].name;
            let key = skuTypeArr[i].name;

            for (var ii = 0; ii < cover_arr_items.length; ii++) {
              arrCover[cover_arr_items[ii].name] = cover_arr_items[ii].cover;
            }

            objX[key] = value;

            if (arrCover[value]) {
              objX.cover = arrCover[value];
            }
          }

          arrY.push(objX);
        }

        console.log("arrY", arrY);
        console.log("arrCover", arrCover);

        return arrY;
      };

      var itemDetailArray = [];
      var itemArray = [];
      for (let index = 0; index < dataSkuItemSource.length; index++) {
        const element = dataSkuItemSource[index];

        if (element.items.length > 0) {
          itemDetailArray.push(element.items);
        }

        itemArray.push(element);
      }
      this.itemDetailArray = itemDetailArray;
      this.itemArray = itemArray;

      console.log("itemDetailArray", itemDetailArray);

      console.log("itemArray", itemArray);

      let arrX = cartesianProductOf(...itemDetailArray);

      let arrY = cartesianTableDataOf(arrX, itemArray);

      console.log("arrX", arrX);
      console.log("arrY", arrY);

      this.formData = arrY;

      // console.log('this.tableField',this.tableField);
      // this.formData = this.tableField.reduce(
      //   (a, b, c) => {
      //     let res = [];
      //     a.map((x) => {
      //       b.items.map((y) => {
      //         console.log("x", x);
      //         console.log("y", y);
      //         if (!x.price) {
      //           Object.assign(x, { cover: "", stock: 0, price: 0 });
      //         }
      //         let data = this.detailData.find((d) => d[b.name] == y.name);
      //         console.log("是否存在", data);

      //         res.push({ ...x, ...data, [b.name]: y.name });
      //       });
      //     });
      //     return res;
      //   },
      //   [{}]
      // );
      // console.log(" this.formData====================", this.formData);
    },

    getFormDataDemo3() {
      this.formData = [];
      let init = this.tableField[0];
      this.tableField[0].items.forEach((item, index) => {
        console.log("item", item);
        let obj = {};

        this.tableField.forEach((lastFiled, lastIndex) => {
          //   obj[init.name] = item.name;
          obj[init.name] = item.name;

          lastFiled.items.forEach((lastItemField, lastItemIndex) => {
            obj[lastFiled.name] = lastItemField.name;
          });
          this.formData.push(obj);
          obj = {};
        });
        console.log("obj", obj);
      });
      console.log("底部表格数据", this.formData);
    },
    getFormDataDemo2() {
      this.formData = [];
      console.log("顶部表格字段", this.tableField);

      //   field, fieldIndex
      this.tableField.forEach((item, index) => {
        item.items.forEach((field, fieldIndex) => {
          let obj = {};

          if (!this.tableField[index + 1] && this.tableField.length == 1) {
            // 赋值表头字段 颜色 =》 蓝
            obj[item.name] = field.name;
            if (!obj.price) {
              Object.assign(obj, { cover: "", stock: 0, price: 0 });
            }
            console.log("当前数据", this.tableField[index]);
            this.formData.push(obj);
            obj = {};
          } else {
            let lastNext = this.tableField[index + 1];
            if (!lastNext) return;
            lastNext.items.forEach((lastField, lastIndex) => {
              obj[lastNext.name] = lastField.name;
              obj[item.name] = field.name;

              if (!obj.price) {
                Object.assign(obj, { cover: "", stock: 0, price: 0 });
                this.formData.push(obj);
                obj = {};
              }
            });
          }

          //   let lastNext = this.tableField[index + 1];
          //   if (lastNext?.name) {
          //   }
          //   lastNext.items.forEach((lastItem, lastIndex) => {
          //     obj[lastNext.name] = lastItem.name;
          //     if (!obj.price) {
          //       Object.assign(obj, { cover: "", stock: 0, price: 0 });
          //     }
          //   });
          //   if (
          //     !this.tableField[index + 1] ||
          //     this.tableField[index].items.length == 1
          //   ) {
          //     if (!obj.price) {
          //       Object.assign(obj, { cover: "", stock: 0, price: 0 });
          //     }
          //     this.formData.push(obj);
          //     obj = {};
          //     return;
          //   }

          //   this.formData.push(obj);
          //   obj = {};
        });
      });

      console.log("底部表格值", this.formData);
    },

    getFormDataDemo() {
      this.formData = [];
      console.log("this.tableField", this.tableField);
      console.log("this.detailData", this.detailData);
      this.tableField.forEach((item, index) => {
        let id = item.name;

        item.items.forEach((i, iIndex) => {
          let obj = {};
          let id2 = i.name;
          let data = this.detailData.find((d) => d[id] == id2);
          if (!this.tableField[index + 1] && this.tableField.length == 1) {
            obj[id] = id2;
            this.formData.push({
              ...{ cover: "", stock: 0, price: 0 },
              ...item,
              ...obj,
              ...data,
            });
            obj = {};
            return;
          }
          let i2Form = this.tableField[index + 1];
          console.log("formformform", i2Form);

          let id3 = i2Form?.name;
          i2Form?.items.forEach((form) => {
            obj[id] = id2;
            obj[id3] = form?.name;
            console.log("form.name", form.name);

            let data = this.detailData.find((d) => d[id] == id3);
            console.log("datadatadata", data);
            this.formData.push({
              //   ...{ cover: "", stock: "", price: "" },
              ...item,

              ...obj,
              ...data,
            });
            obj = {};
          });
        });
      });
      console.log("formData", this.formData);
    },
    // 添加规格值
    standard_value(scope) {
      console.log("添加规格值", scope);
      this.tableField[scope.$index].items.push({
        name: "",
        cover: "",
      });
    },
    fieldClear() {
      this.formData = [];

      this.getFormData();
    },
    titileFieldBlur(row) {
      //   this.formData = [];
      console.log("修改的", row);
      this.formField = this.tableField.map((i) => i.name);
      this.formData.forEach((item) => {
        item[row.name] = item[this.editField];
        delete item[this.editField];
      });
      console.log("  this.formData", this.formData);
      //   this.getFormData();
    },
    fieldFile() {
      this.getFormData();
    },
    fieldBlur(row, name, index, tabIndex) {
      let isHave = this.formData.find((i) => i[row.name] == name);
      console.log("isHaveisHave", isHave);
      // if (name !== isHave[row.name] && isHave) {
      // if (isHave) {
      //   this.$message({
      //     message: `已拥有"${name}"规格属性`,
      //     type: "warning",
      //   });
      //   // this.tableField[tabIndex].items[index].name = "";
      //   this.tableField[tabIndex].items.splice(index, 1);
      //   return;
      // }
      this.formField = this.tableField.map((i) => i.name);
      this.formData = [];

      this.getFormData();
      console.log("触发了改变值", this.formField);
    },

    delDataBase(row, index) {
      console.log("要删除的index", index);
      this.tableField.splice(index, 1);
      this.getFormData();
      this.titileFieldBlur();
    },
    // 删除规格值
    deletecustom(formIndex, index) {
      console.log("formIndex", formIndex);
      console.log("index", index);
      this.formData = [];

      this.tableField[formIndex].items.splice(index, 1);
      this.getFormData();
    },
    // filterImg(row) {
    //   // console.log("字段formField", this.formField);
    //   // console.log("原数据", this.formData);
    //   // console.log("row", row);
    //   let field = this.formField[0];
    //   let typeField = this.formData.filter((item) => item[field] == row[field]);
    //   let cover = typeField.find((i) => i.cover)?.cover;
    //   // console.log("过滤的", typeField);
    //   // console.log("拿到的图片,", cover);
    //   return cover || "";
    // },
    fileInput(src, index) {
      console.log("上传的", src);
      // scope.row.cover = $event
      let edit = this.formData[index];
      edit.cover = src;
      this.$set(this.formData, index, edit);
      console.log("修改的", this.formData);
    },
    // 添加组
    addField(row, index) {
      this.tableField.push({
        items: [],
        name: "", //规格名
        cover: "",
      });
    },
  },
};
</script>
<style lang='scss' scoped>
::v-deep .el-button--primary {
  height: 39px;
}
::v-deep .el-icon-picture-outline:before {
  size: 20px;
}
</style>