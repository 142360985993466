<template>
  <div class="bg-white pd20 box-shadow">
    <div class=" pdl40">
      <el-form ref="form" :model="form" label-width="120px">
        <el-form-item label="商品分类">
          <el-cascader v-model="form.category_ids" placeholder="请输入分类" :options="citiesList" @change="categoryClick(form.category_ids)" filterable clearable></el-cascader>
        </el-form-item>

        <el-form-item label="商品名称" required>
          <el-input v-model="form.name" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="商品编号">
          <el-input v-model="form.goods_sn" placeholder=""></el-input>
        </el-form-item>
        <!-- 
        <el-form-item label="商品详情介绍">
          <el-input type="textarea" v-model="form.describe" placeholder="请输入商品详情介绍" :autosize="{ minRows: 10, maxRows: 30}"></el-input>
        </el-form-item> -->

        <!-- 多张 -->
        <el-form-item label="商品轮播图">
          <file-list :fileList='form.chart_img' @input='imgEdit1'></file-list>
        </el-form-item>

        <el-form-item label="商品属性">
          <specification @sku="skuSon" ref="specification"></specification>
        </el-form-item>

        <!-- 体育显示 -->
        <el-form-item label="商品参数" v-if="$store.state.industry_type == 'sport'">
          <div class="flex">
            <el-form-item label="品类">
              <el-select v-model="form.goods_type_id" placeholder="请选择品类" clearable filterable>
                <el-option v-for="(item,i) in categoryList" :key="i" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="版型">
              <el-select v-model="form.goods_pattern_id" placeholder="请选择版型" clearable filterable>
                <el-option v-for="(item,i) in patternList" :key="i" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="材质">
              <el-select v-model="form.goods_material_id" placeholder="请选择材质" clearable filterable>
                <el-option v-for="(item,i) in textureList" :key="i" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="款式">
              <el-select v-model="form.goods_style_id" placeholder="请选择款式" clearable filterable>
                <el-option v-for="(item,i) in styleList" :key="i" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form-item>
        <!-- 数码显示 -->
        <el-form-item label="商品参数" v-if="$store.state.industry_type == 'digital'">
          <div>
            <el-select v-model="classId" placeholder="请选择" clearable @change="selectClass">
              <el-option v-for="(item,i) in classData" :key="i" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
            <el-button @click="addInput()">添加自定义参数</el-button>
          </div>
          <div class="flex flex-wrap" v-if="form.goods_param">
            <div class="flex mrt20" v-for="(item,index) in form.goods_param" :key="index">
              <el-input v-model="item.name" placeholder="请输入参数名" style="width: 150px;" :readonly="item.id !=='' ? true : false"></el-input>
              <el-input v-model="item.value" placeholder="请输入参数值" style="width: 200px;"></el-input>
              <div class="mrl5 mrr20">
                <el-button type="info" icon="el-icon-minus" circle @click="deletecustom(item,index)"></el-button>
              </div>
            </div>
          </div>
        </el-form-item>

        <el-form-item label="配送费">
          <div>
            <el-radio v-model="form.delivery_type" label="0" @input="deliveryClick">包邮</el-radio>
            <el-radio v-model="form.delivery_type" label="1">到付</el-radio>
            <el-radio v-model="form.delivery_type" label="2">统一邮费</el-radio>
            <el-input-number v-model="form.delivery_price" :disabled="form.delivery_type == 2 ? false :  true "></el-input-number>
          </div>
        </el-form-item>
        <el-form-item label="商品详情">
          <file-list :fileList='form.content' @input='imgEdit2'></file-list>
        </el-form-item>
      </el-form>

    </div>

    <div class="flexcc">
      <el-button style="margin-top: 12px;" @click="back()">返回</el-button>
      <el-button type="primary" style="margin-top: 12px;" @click="confirm()">保存商品</el-button>
    </div>

    <!-- 新增分类 -->
    <el-dialog title="新增分类" :visible.sync="ClassifyVisible" width="30%">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="分类名" required>
          <el-input v-model="classify.name" placeholder="请输入分类名"></el-input>
        </el-form-item>
        <el-form-item label="排序">
          <el-input v-model="classify.sort" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="classify.status" placeholder="请选择状态" clearable>
            <el-option v-for="item in statusDetail" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ClassifyVisible = false">取 消</el-button>
        <el-button type="primary" @click="addClassify">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import specification from "./specification";
import fileList from "@/components/common/fileList";
import filePic from "@/components/common/filePic";
import { statusOption, statusDetail } from "@/utils/statusData";

export default {
  components: { fileList, filePic, specification },
  props: {},
  data() {
    return {
      value: "",
      editId: "",
      ClassifyVisible: false,

      active: 0,
      goodsVlaue: "",
      statusDetail,
      category_id_key: 1,
      category_id2_key: 1,
      category_id3_key: 1,
      pageInfo: {
        limit: 1000000,
        page: 1,
        count: 0,
        pageNum: 0,
      },
      form: {
        chart_img: [],
        content: [],
        name: "",
        delivery_type: "",
        delivery_price: "",
        category_ids: [],
        goods_type_id: "",
        goods_pattern_id: "",
        goods_material_id: "",
        goods_style_id: "",
        goods_sn: "",
        describe: "",
        stock: "",
        sku: {},
        goods_param: [
          {
            name: "",
            value: "",
            id: "",
          },
        ],
      },
      classify: {
        name: "",
        sort: "",
        status: "",
      },

      citiesList: [], //标签列表
      this: [],
      categoryList: [],
      textureList: [],
      styleList: [],
      patternList: [],
      classData: [],
      classId: "", //商品参数id
      optionData: {},
    };
  },

  created() {
    this.getEditList();
    this.getClassify();
    this.getClassifyList();
    this.getGoodsGoods();
    this.getGoodsTexture();
    this.getGoodsStyle();
    this.getGoodsPattern();
    this.getClass();
  },
  mounted() {},
  computed: {},

  methods: {
    categoryClick(val) {
      console.log("选择了", val);
    },
    async getClass() {
      const { data } = await this.$Api.goodsGoodsParam();
      this.classData = data.list;
    },
    selectClass(val) {
      this.classId = val;
      this.parameterList();
    },
    deliveryClick() {
      this.form.delivery_price = 0;
    },
    async parameterList() {
      if (this.classId == "") {
        this.classId = this.classData[0]?.id;
      }
      const { data } = await this.$Api.goodsGoodsParamText({
        param_id: this.classId,
      });
      this.form.goods_param = data?.list?.map((item) => {
        // console.log("item", item.value);
        return {
          name: item.value,
          value: "",
          id: item.id,
        };
      });
    },
    // 添加组
    addInput() {
      this.form.goods_param.push({
        name: "",
        value: "",
        id: "",
      });
    },
    deletecustom(item, index) {
      console.log("删除的", index);
      this.form.goods_param.splice(index, 1);
    },
    async getClassifyList() {
      const { data } = await this.$Api.categoryAllChildren();
      data.forEach((item) => {
        if (!item.children.length) {
          delete item.children;
        }
      });
      this.citiesList = data;

      console.log(" this.citiesList", this.citiesList);
    },
    // 品类
    async getGoodsGoods() {
      const { data } = await this.$Api.goodsGoodsType(this.pageInfo);
      this.categoryList = data.list;
    },

    // 材质
    async getGoodsTexture() {
      const { data } = await this.$Api.goodsGoodsMaterial(this.pageInfo);
      this.textureList = data.list;
    },

    async getGoodsStyle() {
      const { data } = await this.$Api.goodsGoodsStyle(this.pageInfo);
      this.styleList = data.list;
      console.log("款式", this.styleList);
    },

    async getGoodsPattern() {
      const { data } = await this.$Api.goodsGoodsPattern(this.pageInfo);
      this.patternList = data.list;
    },

    back() {
      this.$router.back();
    },
    imgEdit2(val) {
      this.form.content = val;
      // console.log("val", val);
      // console.log("valformform", this.form);
    },
    imgEdit1(val) {
      this.form.chart_img = val;
      console.log("val", val);
      // console.log("valformform", this.form);
    },
    skuSon(val) {
      // console.log("子传父的", val);
      this.form.sku = val;
    },
    change1: function (val) {
      console.log("选择第一个的id", val);
      this.form.category_id = val;
      this.form.category_id2 = "";
      this.form.category_id3 = "";
      this.getClassify2(val);
    },

    change2: function (val) {
      console.log("选择第二个的id", val);
      this.form.category_id2 = val;
      this.form.category_id3 = "";
      this.getClassify3();
    },

    upData(form, formData) {
      Object.keys(formData).forEach((item) => {
        // console.log(item);
        formData[item] = form[item];
      });
    },
    async confirm() {
      let attrs = this.$refs.specification.tableField;
      // console.log(
      //   "this.$refs.specification.tableField",
      //   this.$refs.specification.tableField
      // );
      // this.$refs.specification.tableField.forEach((item) => {
      //   attrs[item.name] = item.items.map((i) => i.name);
      // });
      // console.log(
      //   "this.$refs.specification.formData",
      //   this.$refs.specification.formData
      // );
      let sku = this.$refs.specification.formData;
      console.log("skuskusku", sku);
      console.log("attrs", attrs);
      this.form.sku = {
        attrs,
        sku,
      };
      //   console.log("提交", {
      //     ...this.form,
      //     goods_id: this.editId,
      //     shop_id: sessionStorage.getItem("shopId"),
      //   });
      //   return;
      // console.log("要提交的图片this.form.content", this.form.chart_img);
      // let content = this.form.content.map((i) => i.url);
      // let chart_img = this.form.chart_img.map((i) => i.url);

      // if (this.form.category_ids.length <= 0) {
      //   this.$message.error("请选择分类");
      //   return;
      // }
      let tree = this.citiesList.find((i) => i.value == this.form.category_ids);
      console.log("treetree", tree);
      console.log("this.citiesList", this.citiesList);
      if (this.form.category_ids == "") {
        this.$message.error("请选择分类");
        return;
      }
      if (tree && tree.children?.length) {
        this.$message.error("请选择分类");
        return;
      }
      if (this.form.name == "") {
        this.$message.error("请填写名字");
        return;
      }
      const data = await this.$Api.sportGoodsEdit({
        ...this.form,
        goods_id: this.editId,
        shop_id: sessionStorage.getItem("shopId"),
        // content: content,
        // chart_img: chart_img,
      });
      if (data.code !== 200) {
        this.$message.error(data.msg);
        return;
      }
      this.$message.success("提交成功");

      this.$router.push("/sport/goodslist");
    },
    // 编辑回显
    async getEditList() {
      if (this.$route.query.id) {
        let id = this.$route.query.id;
        const { data } = await this.$Api.sportGoodsDetail({ goods_id: id });
        let formData = { ...data, ...data.item };
        console.log("合并的对象？", formData);
        let {
          status_end_time,
          status_start_time,
          delivery_type,
          category_ids,
          category_name,
        } = formData;
        console.log("data获取编辑字段", data);
        this.editId = data.id;
        if (data.goods_param != "") {
          this.form.goods_param = data.goods_param.map((item) => {
            return {
              name: item.name,
              value: item.value,
              id: item.id,
            };
          });
        }

        this.form.chart_img = data.chart_img ? data.chart_img : [];
        this.form.content = data.content ? data.content : [];

        //
        this.$refs.specification.formField = data.sku.sku_group.map(
          (i) => i.name
        );

        this.$refs.specification.tableField = data.sku.sku_group.length
          ? data.sku.sku_group
          : [
              {
                items: [],
                name: "", //规格名
                cover: "",
                stock: "",
                price: "",
              },
            ];

        this.$refs.specification.formData = data.sku.sku_item;
        this.$refs.specification.detailData = data.sku.sku_item;

        // this.$refs.specification.getFormData();
        this.getClassify();
        this.getClassify2();
        this.getClassify3();

        formData.timeValue = [status_start_time, status_end_time];

        formData.delivery_type = delivery_type.toString();
        formData.category_ids = category_ids.map((item) => item.id);
        // console.log("最后的this.form", this.form);
        // console.log("最后的formData", formData);
        this.upData(formData, this.form);
      } else {
        this.parameterList();
      }
    },

    // 分类
    async getClassify() {
      let { data } = await this.$Api.goodsCategory();
      console.log(data, "体育分类列表");
      this.optionData.com1 = data;
      this.category_id_key++;
    },

    async getClassify2(row) {
      let { data } = await this.$Api.goodsCategory({
        pid: this.form.category_id,
      });
      this.optionData.com2 = data;
      this.category_id2_key++;
    },
    async getClassify3() {
      let { data } = await this.$Api.goodsCategory({
        pid: this.form.category_id2,
      });

      console.log(data, "第三层分类");
      this.optionData.com3 = data;
      this.category_id3_key++;
    },

    // 新增分类
    async addClassify() {
      console.log("新增管理", this.classify);
      const data = await this.$Api.animeCategoryEdit(this.classify);
      if (data.code !== 200) {
        this.$message.error(data.msg);
        return;
      }
      this.getClassify();
      this.$message.success("新增成功");
      this.ClassifyVisible = false;
      this.classify = {
        name: "",
        sort: "",
        status: "",
      };
    },
  },
};
</script>
<style lang='scss' scoped>
.edit_field {
  border-right: 1px solid black;
  height: 40px;
}
</style>
