<template>
  <div>
    <!-- {{img}} -->
    <div v-loading="loading" class="imgInput" :style="{width,height}" @mouseenter.prevent="showTool= true" @mouseleave.prevent="showTool = false" @drop="loadFile" @dragenter.prevent="showTips" @dragover.prevent="showTips" @click="getFile">
      <div v-if="tips" class="tips">
        <div class="icon el-icon-picture-outline" />
        <div>{{disabled? '':emptyText}}</div>
      </div>
      <input v-show="false" ref="file" type="file" accept=".jpg,.png,.gif" @change="loadFile">
      <div v-if="!tips&&!disabled" class="removeBtn el-icon-error" @click.stop="removeFile" />
      <div class="show_btn justify-around align-center fullscreen flex" v-if="!tips&&showTool">
        <div class="el-icon-zoom-in white size18 pointer" @click.stop="showImg">
        </div>
        <div class="el-icon-download white size18 pointer" @click.stop="handleDownload">
        </div>
      </div>

      <el-image class="cover" :src="img">
        <template #error>
          <div class="tips" />
        </template>
      </el-image>
    </div> <!-- 查看大图 -->
    <el-dialog :visible.sync="dialogVisible" append-to-body center title="图片查看">
      <template slot="title">

      </template>

      <el-image-viewer v-if="dialogVisible" :on-close="closeViewer" :zIndex="8000" :url-list="[img]" index="9999" />

    </el-dialog>
    <!-- 图片查看器 -->
  </div>

</template>

<script >
import { file, apiUploadFilePost } from "@/api";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import ImageCompressor from "image-compressor.js";

export default {
  name: "FormSingleImageFile",
  components: { ElImageViewer },
  props: {
    imgType: {
      type: String,
      default: "avatar",
    },
    file: {
      type: String,
      default: "",
    },
    emptyText: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    width: {
      default: "50px",
    },
    height: {
      default: "50px",
    },
  },
  data() {
    return {
      msg: "",
      loading: false,
      blob: null,
      showTool: false,
      dialogVisible: false,
    };
  },
  computed: {
    img: {
      get() {
        return this.file;
      },
      set(e) {
        console.log("设计阿萨德as", e);
        this.$emit("input", e);
      },
    },
    tips() {
      if (!this.file) {
        return true;
      }
      return false;
    },
  },
  watch: {
    img(n, o) {
      if (!n) {
        this.$refs.file.value = "";
      }
    },
  },
  methods: {
    compressFile(file) {
      return new Promise((resolve, reject) => {
        new ImageCompressor(file, {
          quality: 0.6, //压缩质量
          checkOrientation: false, //图片翻转，默认为false
          success(result) {
            // resolve(result);
            let files = new File([result], file.name, { type: file.type });
            resolve(files);
          },
          error(e) {
            reject();
          },
        });
      });
    },
    closeViewer() {
      this.dialogVisible = false;
    },
    showImg() {
      this.dialogVisible = true;
    },
    handleDownload() {
      window.open(this.img);
    },
    showTips(e) {
      e.preventDefault();
      console.log("123", e);
    },
    getFile() {
      if (this.disabled) {
        return;
      }
      this.$el.querySelector("input").click();
    },
    async loadFile(e) {
      this.loading = true;
      if (e) {
        e.preventDefault();
      }
      //   const files = e.srcElement.files[0];
      const files = await this.compressFile(e.srcElement.files[0]);

      const blob = files.slice(0, files.size, "image/png");
      const newFile = new File([blob], files.name.replace(/[^\w\.]/gi, ""), {
        type: "image/png",
      });
      console.log("newFile", newFile);
      const formData = new FormData();
      formData.append("photo", newFile);

      try {
        console.log("formData", formData);
        console.log("newFile", newFile);
        // Api
        let res = await file(formData).finally(() => (this.loading = false));
        this.img = res.data.url;
      } catch (error) {
        console.log("errorrrrr", error);
        this.loading = false;

        this.$notified.error({
          message: "图片上传失败",
        });
      }
    },
    removeFile(e) {
      if (e) {
        e.preventDefault();
      }
      this.img = "";
      this.blob = null;
    },
  },
};
</script>

<style scoped lang='scss'>
@import "~element-ui/packages/theme-chalk/src/common/var";
/deep/ .el-dialog__body {
  padding: 0;
}
/deep/ .el-dialog__header {
  display: none;
}
.imgInput {
  position: relative;
  min-width: 50px;
  min-height: 50px;
  border: 2px dashed #eee;
  border-radius: $--border-radius-base;
}
.cover {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  cursor: pointer;
  .image-slot {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: white;
  }
}
.removeBtn {
  color: $--color-danger;
  z-index: 1000;
  position: absolute;
  right: 0px;
  top: 0px;
  transform: translateX(50%) translateY(-50%);
  background-color: white;
  border-radius: 100%;
  box-shadow: $--box-shadow-dark;
  cursor: pointer;
}
.show_btn {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 2;
  background: rgba($color: #000000, $alpha: 0.4);
}
.tips {
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none;
  left: 0px;
  top: 0px;
  z-index: 10;
  user-select: none;
  color: $--color-text-secondary;
  font-size: $--font-size-base;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 8px;
  .icon {
    font-size: 30px;
    padding: 8px;
  }
}
</style>
